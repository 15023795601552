@import "./layouts/stylesheet.css";

* {
  box-sizing: border-box;
}

a:hover {
  color: #00287c;
  cursor: pointer;
}
/* a, */
/* a:visited, */
a:active,
a:link {
  color: #3750ac;
  text-decoration: none;
}

a:visited {
  color: inherit;
}
.wrap_f305 {
  z-index: 100 !important;
}

body {
  margin: 0;
  font-family: "Euclid Circular B", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.iframeHolder {
  width: 100%;
  height: 100% !important;
}

.iframeBox {
  border-radius: 4px;
  position: absolute;
  width: 420px;
  height: 98%;
  z-index: 1010;
  margin-left: calc(50% - 210px) !important;
  background-color: #fff;
  padding: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.showOutsiders > div > .MTableToolbar-spacer-30 {
  display: none;
}

.authCode {
  display: grid;
  grid-template-columns: repeat(6, 36px);
  /* gap: 8px; */
}

.authCodeInput {
  font-family: monospace;

  -moz-appearance: textfield;
  border-radius: 6px;
  border: 1px solid;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin: 2px;
  padding-left: 8px;
  width: 32px;
  height: 42px;
  font-size: 18px;
  box-sizing: border-box;
  color: black;
  background-color: white;
  border-color: lightgrey;
}

.authCodeInput:focus-visible {
  border-color: #00287c;
  outline: #3750ac;
}
